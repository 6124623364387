<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">USER's DETAILS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="id_no"
                label="ID NO"
                dense
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="password"
                label="PASSWORD"
                dense
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Name"
                dense
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="positions"
                dense
                outlined
                label="Positions"
                :items="['ADMIN','BRANCH MANAGER']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-select
                v-model="district"
                dense
                outlined
                label="District/Club"
                :items="district_items"
                item-value="id"
                item-text="district_name"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                dense
                outlined
                v-model="branch"
                :items="branch_items"
                item-text="branch_code"
                item-value="id"
                label="Branch"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_staff_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <v-card-text class="mt-4">
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table dense
                        :headers="headers"
                        :items="officers_items"
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.id_no }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.positions }}
                </td>
                <td>
                  {{ item.district_id===null?'':item.district.district_name }}
                </td>
                <td>
                  {{ item.password_view }}
                </td>
                <td class="text-center">
                  <v-icon
                    class="mr-2"
                    color="info"
                    @click="edit_details(item)"
                  >
                    {{icons.mdiPencilBox}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table dense
                        :headers="headers"
                        :items="user_items"
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <h2>
                  Print
                  <v-icon
                    class="mr-2"
                    color="success"
                    @click="print_selected_deposit_slip_data()"
                  >
                    {{icons.mdiPrinter}}
                  </v-icon>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.id_no }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.positions }}
                </td>
                <td>
                  {{ item.district_id===null?'':item.district.district_name }}
                </td>
                <td>
                  {{ item.password_view }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-dialog v-model="is_edit" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CHANGE DISTRICT/CLUB AND POSITION</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" class="multi-col-validation mt-6">

          <v-card-text>
            <v-col
              md="4"
              cols="12"
            ></v-col>
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="id_no"
                  label="ID NO"
                  dense
                  outlined
                  :rules="rules.default_max_100_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="password"
                  label="PASSWORD"
                  dense
                  outlined
                  :rules="rules.default_max_100_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                md="4"
                cols="12"
              >
                <v-text-field
                  v-model="name"
                  label="Name"
                  dense
                  outlined
                  :rules="rules.default_max_100_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                md="4"
                cols="12"
              >
                <v-select
                  v-model="district"
                  dense
                  outlined
                  label="District/Club"
                  :items="district_items"
                  item-value="id"
                  item-text="district_name"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="positions"
                  dense
                  outlined
                  label="Positions"
                  :items="['ADMIN','BRANCH MANAGER']"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
            </v-row>
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="save_change"> Save Changes</v-btn>
            <v-btn color="error" @click="is_edit = false"> Close</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAlertOutline, mdiCloudUploadOutline
    , mdiPencilBox
    , mdiGoogleCirclesCommunities
    , mdiAccountOutline
    , mdiAccountSearch
    , mdiPrinter
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from "moment";

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiAccountSearch,
          mdiCloudUploadOutline,
          mdiPencilBox,
          mdiGoogleCirclesCommunities,
          mdiAccountOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        branch: '',
        branch_items: [],
        district_items: [],
        selected_user: {},
        district: '',
        search: '',

        is_edit: false,
        saving: false,
        alert: false,
        alert_message: '',

        name: '',
        id_no: '',
        password: '',
        positions: '',

        user_items: [],
        officers_items: [],
        tab: '',
        tabs: [
          {title: 'OFFICERS', icon: mdiGoogleCirclesCommunities},
          {title: 'USERS', icon: mdiAccountOutline},
        ],
        headers: [
          {text: 'ID', value: 'id', sortable: true},
          {text: 'NAME', value: 'name', sortable: true},
          {text: 'POSITION', value: 'positions', sortable: true},
          {text: 'DISTRICT', value: 'email_address', sortable: true},
          {text: 'PASSWORD', value: 'password_view', sortable: true},
          {text: 'ACTION', value: 'action', sortable: true},
        ],
      }
    },
    created() {
      const data = new FormData()
      data.append('active', 0);
      this.get_list_of_all_active_branch(data)
        .then(response => {
          this.branch_items = response.data
        })
      this.list_of_district()
        .then(response => {
          this.district_items = response.data
        })
      this.list_of_user()
        .then(response => {
          this.user_items = response.data[0].users
          this.officers_items = response.data[0].officers
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('branch', ['get_list_of_all_active_branch']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['register_user', 'is_user_already_encoded', 'list_of_user', 'update_user_details']),
      ...mapActions('district', ['list_of_district']),
      reset() {
        this.is_edit = false
        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.selected_user = {}

        this.name = ''
        this.password = ''
        this.id_no = ''
        this.positions = ''
      },
      save_staff_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var index = this.branch_items.map(function (x) {
            return x.id;
          }).indexOf(this.branch)
          if (index != -1) {
            const data = new FormData()
            data.append('branch_id', this.branch_items[index].id);
            data.append('name', this.name.toUpperCase());
            data.append('password', this.password);
            data.append('id_no', this.id_no);
            data.append('positions', this.positions);
            data.append('district_id', this.district);
            data.append('merchant_id', '');
            this.is_user_already_encoded(data)
              .then(response => {
                if (response.data == 1) {
                  this.alert = true
                  this.alert_message = 'User Already Encoded!'
                  this.saving = false
                } else {
                  this.register_user(data)
                    .then(response => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: response.data,
                      })
                      this.reset()
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      edit_details(item) {
        this.is_edit = true
        this.selected_user = item
        this.id_no = item.id_no
        this.name = item.name
        this.positions = item.positions
        this.district = item.district_id
      },
      save_change() {
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.selected_user.id);
          data.append('name', this.name.toUpperCase());
          data.append('password', this.password);
          data.append('id_no', this.id_no);
          data.append('district_id', this.district);
          data.append('positions', this.positions);
          this.update_user_details(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
              this.list_of_user()
                .then(response => {
                  this.user_items = response.data[0].users
                  this.officers_items = response.data[0].officers
                })
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      print_selected_deposit_slip_data() {
        var imgData = this.company_logo
        var payments_array = []
        payments_array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'NAME', alignment: 'center', style: 'label'},
            {text: 'POSITION', alignment: 'center', style: 'label'},
            {text: 'DISTRICT/CLUB', alignment: 'center', style: 'label'},
            {text: 'PASSWORD', alignment: 'center', style: 'label'},
          ]
        )
        if (this.user_items.length > 0) {
          this.user_items.forEach(function (item, index, payment) {
            payments_array.push(
              [
                {text: item.id_no, alignment: 'left'},
                {text: item.name, alignment: 'center'},
                {text: item.positions, alignment: 'center'},
                {
                  text: item.district_id === null ? '' : item.district.district_name,
                  alignment: 'center'
                },
                {text: item.password_view, alignment: 'center'},
              ]
            )
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF USERS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [60, 150, 50, 160, 60],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Foundation System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
